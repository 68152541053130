import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/browsersync-gulp",
  "date": "2016-03-30",
  "title": "BROWSERSYNC & GULP",
  "author": "admin",
  "tags": ["development", "javascript", "gulp"],
  "featuredImage": "feature.jpg",
  "excerpt": "BrowserSync & Gulp are a two very useful combination when it comes to developing applications efficiently, without having to refresh the web page every time you make a change. Let’s look at what BrowserSync & Gulp are, and how to install and use them."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`What is Gulp?`}</h2>
    <p>{`Gulp is a tool for automating common tasks or what we call a build system. It’s similar to Grunt as it runs on Node.js and written in JavaScript but Grunt is a configuration driven where as Gulp is stream driven, which comes natural for developers.`}</p>
    <p>{`With Gulp we can write tasks to minify or lint our JavaScript and CSS, parse templates, and compile your SASS when the files change.`}</p>
    <h2>{`What is BrowserSync`}</h2>
    <p>{`BrowserSync is a plugin that seamlessly integrates with tools like Gulp and Grunt. It can run simultaneously on different browsers and devices, and most importantly it can perform live reloading. This makes it a perfect tool to have in our developer toolkit.`}</p>
    <h2>{`Installing Gulp`}</h2>
    <p>{`Gulp comes as an npm package, so installing Gulp is quite effortless. First, install the Gulp package globally:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install gulp –g
`}</code></pre>
    <h2>{`Installing BrowserSync`}</h2>
    <p>{`The preferred way of installing BrowserSync is installing it locally for each project rather than global install. This way a dependency can be added to your package.json when you are publishing your project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install browser-sync ––save–dev
`}</code></pre>
    <p>{`This will add an entry to your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file under `}<inlineCode parentName="p">{`devDependecies`}</inlineCode>{` section.`}</p>
    <Message type="warn" title="Avoid sudo" content="If you are running Mac OSX and having trouble installing BrowserSync, that means you are having issues with NPM permissions. Read the [docs](https://docs.npmjs.com/getting-started/fixing-npm-permissions) to fix it." mdxType="Message" />
    <h2>{`Setting Up a Sample Project`}</h2>
    <p>{`Let’s create a sample project in order to test BrowserSync & Gulp. Create a folder for our sample project. And then inside the project folder, create a folder for sass as well as create an `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`mkdir browsersync-gulp
cd browsersync-gulp
mkdir sass
touch index.html
`}</code></pre>
    <p>{`Run `}<inlineCode parentName="p">{`npm init`}</inlineCode>{` to create a `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file. Follow the prompts to add meta data to your project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm init
`}</code></pre>
    <h2>{`Using BrowserSync & Gulp`}</h2>
    <p>{`To start working with Gulp, we have to create a file named `}<inlineCode parentName="p">{`gulpfile.js`}</inlineCode>{`. Here we are going to define the tasks to run with the `}<inlineCode parentName="p">{`gulp`}</inlineCode>{` command.`}</p>
    <p>{`Inside the `}<inlineCode parentName="p">{`gulpfile.js`}</inlineCode>{`, let’s bring in the `}<inlineCode parentName="p">{`gulp`}</inlineCode>{` and the `}<inlineCode parentName="p">{`browser-sync`}</inlineCode>{` modules.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var gulp = require('gulp');
var browserSync = require('browser-sync').create();
`}</code></pre>
    <p>{`As you can see, we are immediately invoking the `}<inlineCode parentName="p">{`create()`}</inlineCode>{` function of the browser-sync module.`}</p>
    <p>{`Having finished setting up BrowserSync & Gulp, we are going to bring in `}<inlineCode parentName="p">{`gulp-sass`}</inlineCode>{` for compiling SASS into CSS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install gulp-sass ––save–dev
`}</code></pre>
    <p>{`Now it’s the time to add tasks to the gulpfile.js. Let’s start with creating a task for compiling SASS into CSS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`...
gulp.task('sass', function() {
    return gulp.src("sass/*.scss")
        .pipe(sass())
        .pipe(gulp.dest("css"))
        .pipe(browserSync.stream());
});
...
`}</code></pre>
    <p>{`The sass task looks into the `}<inlineCode parentName="p">{`sass`}</inlineCode>{` directory and detects any changes to the files with `}<inlineCode parentName="p">{`.scss`}</inlineCode>{` extension. If there were any changes, it will invoke sass compiler and the result will be saved in the `}<inlineCode parentName="p">{`css`}</inlineCode>{` folder. This output will be piped into the browserSync process, which in turn refreshes the web page by auto-injecting into the browser.`}</p>
    <Message type="info" title=".stream(opts)" content="The stream method returns a transform stream and can act once or on many files. – **browsersync**" mdxType="Message" />
    <p>{`The `}<inlineCode parentName="p">{`serve`}</inlineCode>{` task initializes browserSync and also watches for file changes in the SASS, CSS and HTML files. If a change has been detected, it will reload the web page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`...
gulp.task('serve', ['sass'], function() {
 
    browserSync.init({
        server: "."
    });
 
    gulp.watch("sass/*.scss", ['sass']);
    gulp.watch(["css/*.css", "*.html"]).on('change', browserSync.reload);
});
...
`}</code></pre>
    <p>{`Finally, we will add the `}<inlineCode parentName="p">{`default`}</inlineCode>{` task, which in turn runs the `}<inlineCode parentName="p">{`serve`}</inlineCode>{` task. And that’s all you need to run a basic web app with live reload and SASS.`}</p>
    <p>{`Let’s look at the final `}<inlineCode parentName="p">{`gulpfile.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var gulp = require('gulp');
var browserSync = require('browser-sync').create();
var sass = require('gulp-sass');
 
gulp.task('sass', function() {
    return gulp.src("sass/*.scss")
        .pipe(sass())
        .pipe(gulp.dest("css"))
        .pipe(browserSync.stream());
});
 
gulp.task('serve', ['sass'], function() {
 
    browserSync.init({
        server: "."
    });
 
    gulp.watch("sass/*.scss", ['sass']);
    gulp.watch(["css/*.css", "*.html"]).on('change', browserSync.reload);
});
 
gulp.task('default', ['serve']);
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`As you can see, BrowserSync & Gulp is a great combination for up and running with your new projects for building web apps.`}</p>
    <p>{`The Github source for this article can be found in `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/browsersync-gulp-boilerplate"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      